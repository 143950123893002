<template>
    <div class="w-100 h-100" id="center">
      <div class="d-flex h-100">
        <dv-border-Box-12 class="flex-1">
          <div class="w-100 h-100 d-flex flex-column ai-center jc-center">
            <div class="text">楼栋总数：{{devData.floorCount}}栋</div>
            <div class="text">设备总数：{{devData.deviceCount}}个</div>
          </div>
        </dv-border-Box-12>
        <dv-border-Box-12 class="flex-1">
          <div class="w-100 h-100 d-flex flex-column ai-center jc-center">
            <div class="text">已处理告警：{{devData.processedAlarmsCount}}次</div>
            <div class="text">未处理告警：{{devData.unprocessedAlarmsCount}}次</div>
          </div>
        </dv-border-Box-12>
      </div>
      <div class="d-flex h-100">
        <dv-border-Box-12 class="flex-1">
          <div class="w-100 h-100 d-flex flex-column ai-center jc-center">
            <div class="text">告警总数：{{devData.totalAlarmsCount}}次</div>
            <div class="text">误报告警：{{devData.totalFalseAlarmsCount}}次</div>
          </div>
        </dv-border-Box-12>
        <dv-border-Box-12 class="flex-1">
          <div class="w-100 h-100 d-flex flex-column ai-center jc-center">
            <div class="text">离线设备数：{{devData.totalOfflineCount}}个</div>
            <div class="text">在线设备数：{{devData.totalOnlineCount}}个</div>
          </div>
        </dv-border-Box-12>
      </div>
    </div>
</template>

<script>
export default {
  props:{
    list:{
      type: Object,
      default () {
        return {}
      }
    }
  },
  data() {
    return {
      devData:{}
    };
  },
  watch: {
    list: {
      handler(newData) {
        this.devData = newData;
      },
      immediate: true,
      deep: true
    }
  }
};
</script>

<style lang="scss" scoped>
#center {
  display: flex;
  flex-direction: column;
  .text {
    font-size: 0.35rem;
    font-weight: bold;
    color: #11d0c9;
    line-height: 2;
  }
}
</style>