<template>
  <div id="monitor">
    <div class="bg-color-black">
      <div class="d-flex ai-center py-2 pl-2">
        <span style="color:#5cd9e8">
          <icon name="video" scale="0.8"></icon>
        </span>
        <div class="d-flex ai-center flex-1">
          <span class="text mx-2">重点单位实时监控</span>
        </div>
        <div class="flex-1"></div>
        <div class="btn" @click="isList = !isList">{{isList ? '返回' : '监控列表'}}</div>
      </div>
      <div class="flex-1 d-flex">
        <div class="flex-1 px-2 py-2" style="overflow:hidden;">
          <div id="video-container1" class="d-flex w-100 h-100"></div>
        </div>
        <!-- <div v-if="list.length >= 2" class="flex-1 px-2 py-2" style="overflow:hidden;">
          <div id="video-container2" class="d-flex w-100 h-100"></div>
        </div> -->
      </div>
      <div v-if="isList" class="list-box d-flex flex-column">
        <div class="d-flex flex-ai-center list-box-item" 
          v-for="(item, i) in list" 
          :key="i"
          :style="{backgroundColor: list[index].deviceId == item.deviceId ? '#171c33' : '#0f1325'}"
          @click="rePlay(i)">
          <div class="flex-1 list-box-text">{{item.deviceId}}</div>
          <div class="flex-1 list-box-text" :title="item.detailAddress">{{item.detailAddress}}</div>
          <div class="flex-1 list-box-text">{{item.installPositionName}}</div>
          <div class="flex-1 list-box-text" :style="{color: item.alarmStatus == 1 ? '#11d0c9' : '#FF4B34'}">{{item.alarmStatus == 1 ? '正常' : '设备告警'}}</div>
        </div>
      </div>
      <!-- <div v-if="list.length >= 3" class="flex-1 d-flex">
        <div v-if="list.length >= 3" class="flex-1 px-2 py-2" style="overflow:hidden;">
          <div id="video-container3" class="d-flex w-100 h-100"></div>
        </div>
        <div v-if="list.length >= 4" class="flex-1 px-2 py-2" style="overflow:hidden;">
          <div id="video-container4" class="w-100 h-100"></div>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import bus from '@/utils/bus';
import EZUIKit from 'ezuikit-js';
export default {
  data() {
    return {
      player1: null,
      // player2: null,
      // player3: null,
      // player4: null,
      index: 0,
      url: 'ezopen://open.ys7.com/F96954173/1.hd.live',
      newUrl: '',
      isList: false,
      dashUrl:""
    };
  },
  props: {
    list: {
      type: Array,
      default() {
        return [];
      }
    },
    isAdmin: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    list: {
      handler (newData) {
        console.log(newData)
        var that = this;
        this.$nextTick(() => {
          if (newData.length >= 1 && this.newUrl == '') {
            this.newUrl = newData[0]?.previewUrlHd??this.url;
            if (this.player1) {
              this.player1.stop();
              this.player1.play({
                url: this.newUrl
              });
            } else {
              this.getAccessToken(token => {
                this.player1 = new EZUIKit.EZUIKitPlayer({
                  id: 'video-container1',
                  accessToken: token,
                  url: this.newUrl,
                  template: 'standard',
                  handleError: res => {
                    if (res.code != 5404 && res.code != 9048 && res.code != 10002) {
                      that.player1.play({
                        url: this.url
                      });
                    } else {
                      if (res.code == 5404) {
                          that.$message({
                            message: '监控设备不在线',
                            type: 'warning',
                            offset:"255",
                            center:true,
                          });
                      }
                    }
                  }
                }); 
                this.player1.play();
              });
            }
          }
          // if (newData.length >= 2) {
          //   if (this.player2) {
          //     this.player2.stop();
          //     this.player2.play({
          //       url: newData[1].previewUrlHd
          //     });
          //   } else {
          //     this.getAccessToken(token => {
          //       this.player2 = new EZUIKit.EZUIKitPlayer({
          //         id: 'video-container2',
          //         accessToken: token,
          //         url: newData[1].previewUrlHd,
          //         handleError: res => {
          //           if (res.code != 5404 && res.code != 9048 && res.code != 10002) {
          //             this.player2.play({
          //               url: this.url
          //             });
          //           }
          //         }
          //       }); 
          //       this.player2.play();
          //     });
          //   }
          // }
          // if (newData.length >= 3) {
          //   if (this.player3) {
          //     this.player3.stop();
          //     this.player3.play({
          //       url: newData[2].previewUrlHd
          //     });
          //   } else {
          //     this.getAccessToken(token => {
          //       this.player3 = new EZUIKit.EZUIKitPlayer({
          //         id: 'video-container3',
          //         accessToken: token,
          //         url: newData[2].previewUrlHd,
          //         handleError: res => {
          //           if (res.code != 5404 && res.code != 9048 && res.code != 10002) {
          //             this.player3.play({
          //               url: this.url
          //             });
          //           }
          //         }
          //       }); 
          //       this.player3.play();
          //     });
          //   }
          // }
          // if (newData.length >= 4) {
          //   if (this.player4) {
          //     this.player4.stop();
          //     this.player4.play({
          //       url: newData[3].previewUrlHd
          //     });
          //   } else {
          //     this.getAccessToken(token => {
          //       this.player4 = new EZUIKit.EZUIKitPlayer({
          //         id: 'video-container4',
          //         accessToken: token,
          //         url: newData[3].previewUrlHd,
          //         handleError: res => {
          //           if (res.code != 5404 && res.code != 9048 && res.code != 10002) {
          //             this.player4.play({
          //               url: this.url
          //             });
          //           }
          //         }
          //       }); 
          //       this.player4.play();
          //     });
          //   }
          // }
        });
      },
      immediate:true,
      deep:true
    }
  },
  components: {},
  created() {
    this.dashUrl =  localStorage.getItem("dashUrl");
    if(this.dashUrl){
      this.isList = true
      return;
    }
    this.getAccessToken(token => {
      if (this.list.length === 0) {
        this.player1 = new EZUIKit.EZUIKitPlayer({
          id: 'video-container1',
          accessToken: token,
          url: this.url,
          template: 'standard',
          handleError: res => {
            if (res.code != 5404 && res.code != 9048 && res.code != 10002) {
              this.player1.play({
                url: this.url
              });
            }
          }
        });
        this.player1.play();
      }
    });

    bus.$on('monitorSetUrl', e => {
      console.log("msu",e)
      if (e) {
        this.player1.stop();
        this.player1.play({
          url: e
        });
      }
    });

  },
  mounted() {},
  destroyed() {
    this.player1.stop();
    // this.player2.stop();
    // this.player3.stop();
    // this.player4.stop();
  },
  methods: {
    getAccessToken(callback) {
      let apiUrl = this.isAdmin ? this.$apis.adminGetYsToken : this.$apis.getYsToken;
      let dashUrl = localStorage.getItem("dashUrl")
      if(dashUrl){
        this.dashUrl = dashUrl;
        apiUrl = this.dashUrl + "/api/dashboard/getYsToken";
      }
      this.$get(apiUrl,{},{},this.dashUrl?true:false).then(res => {
        if (res&&res.code === 0) {
          callback(res.data.accessToken);
        }
      });
    },
    rePlay(index) {
      this.index = index;
      if(this.player1){
      this.player1.stop();
      this.player1.play({
        url: this.list[this.index]?.previewUrlHd??this.url
      });
      }

      this.isList = false;
    }
  }
};
</script>

<style lang="scss" scoped>
#monitor {
  padding: 0.4rem 0.2rem 0.2rem;
  width: 100%;
  height: 100%;
  border-radius: 0.0625rem;
  box-sizing: border-box;
  display: flex;
  .bg-color-black {
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    position: relative;
    .text {
      font-size: 0.2rem;
      color: #11d0c9;
    }
    .btn {
      padding: 0.1rem 0.2rem;
      font-size: 0.2rem;
      color: #c3cbde;
      border-radius: 0.05rem;
      background-color: #568aea;
      cursor: pointer;
    }
    .list-box {
      position: absolute;
      top: 0.75rem;bottom: 0;left: 0;right: 0;
      background-color: #0f1325;
      overflow-y: auto;
      &-item {
        width: 100%;
        padding: 0.3rem 0;
        cursor: pointer;
      }
      &-text {
        text-align: center;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-size: 0.2rem;
        color: #11d0c9;;
        padding:2px 0;
      }
    }
  }
}
</style>