import { render, staticRenderFns } from "./today-notice.vue?vue&type=template&id=ff04ea04&scoped=true&"
import script from "./today-notice.vue?vue&type=script&lang=js&"
export * from "./today-notice.vue?vue&type=script&lang=js&"
import style0 from "./today-notice.vue?vue&type=style&index=0&id=ff04ea04&prod&lang=scss&"
import style1 from "./today-notice.vue?vue&type=style&index=1&id=ff04ea04&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ff04ea04",
  null
  
)

export default component.exports