<template>
  <div class="w-100 h-100">
    <!-- 年度开工率 -->
    <Echart
      :options="options"
      chartId="totalRingChart"
      height="100%"
      width="100%"
    ></Echart>
  </div>
</template>

<script>
import Echart from '@/common/echart'
export default {
  data () {
    return {
      options: {},
      cdata:{
        xData: ["火警", "故障", "离线", "预警"],
        seriesData: []
      }
    };
  },
  components: {
    Echart,
  },
  props: {
    data: {
      type: Object,
      default: () => ({})
    },
  },
  watch: {
    data: {
      handler (t) {
        this.cdata.seriesData = [
          { value: Math.round((t.totalFireAlarmDeviceCount/t.totalEventCount)*100), name: "火警", labelLine: {show: true}, label: {show: true} },
          { value: Math.round((t.totalMalfunctionDeviceCount/t.totalEventCount)*100), name: "故障", labelLine: {show: true}, label: {show: true} },
          { value: Math.round((t.totalOfflineDeviceCount/t.totalEventCount)*100), name: "离线", labelLine: {show: true}, label: {show: true} },
          { value: Math.round((t.totalEarlyWarningDeviceCount/t.totalEventCount)*100), name: "预警", labelLine: {show: true}, label: {show: true} }
        ]
        this.options = {
          title: {
            text: "设备情况月统计",
            textStyle: {
              color: "#11d0c9"
            },
            top: "2%"
          },
          color: [
            "#FF4B34",
            "#FFC700",
            "#999999",
            "#FC7B4F"
          ],
          tooltip: {
            show: true,
            confine: true,
            trigger: 'item',
            formatter: function (params) {
              var res = `${params.marker}${params.data.name}设备: ${params.data.value}%`;
              return res;
            }
          },
          legend: {
            orient: "vertical",
            icon: "circle",
            top: '2%',
            right: '5%',
            data: this.cdata.xData,
            textStyle: {
              color: "#11d0c9"
            }
          },
          series: [
            {
              name: "设备情况月统计",
              type: "pie",
              radius: ['40%', '60%'],
              center: ["40%", "50%"],
              data: this.cdata.seriesData,
              label: {
                normal: {
                  formatter: function(e) {
                    if (e.data.value == 0) {
                      e.data.labelLine.show = false;
                      e.data.label.show = false;
                    }
                  }
                }
              }
            }
          ]
        };
      },
      immediate: true,
      deep: true
    },
  },
}
</script>