
<template>
  <div class="w-100 h-100">
    <Echart
      :options="options"
      chartId="timetotalLineChart"
      height="100%"
      width="100%"
    ></Echart>
  </div>
</template>



<script>
import Echart from '@/common/echart'
export default {
  data () {
    return {
      options: {},
      cdata:{
        month:[],
        eventCount:[]
      }
    };
  },
  components: {
    Echart,
  },
  props: {
    list: {
      type: Array,
      default: () => ([])
    }
  },
  watch: {
    list: {
      handler (t) {
        var month = [];
        var eventCount = [];
        t.forEach(element => {
          if (element.month) {
            month.push(element.month);
            eventCount.push(element.eventCount);
          }
        });
        this.cdata.month = month;
        this.cdata.eventCount = eventCount;
        this.options = {
          title: {
            text: '近半年事件统计',
            textStyle: {
              color: "#11d0c9"
            },
            top: "2%"
          },
          tooltip: {
            trigger: 'axis'
          },
          xAxis: {
            type: 'category',
            data: this.cdata.month,
            axisLabel: {
              color: '#11d0c9'
            }
          },
          yAxis: {
            type: 'value',
            axisLabel: {
              formatter: '{value}',
              color: '#11d0c9'
            }
          }, 
          series: [
            {
              name: '事件数',
              connectNulls: true,
              type: 'line',
              data: this.cdata.eventCount,
              markPoint: {
                data: [
                  {type: 'max', name: '最大值'},
                  {type: 'min', name: '最小值'}
                ]
              },
              markLine: {
                data: [
                  {type: 'average', name: '平均值'}
                ]
              }
            }
          ]
        };
      },
      immediate: true,
      deep: true
    }
  }
}
</script>