<template>
  <div id="index">
    <dv-full-screen-container class="bg">
      <dv-loading v-if="loading">Loading...</dv-loading>
      <div v-else class="host-body">
        <div class="d-flex jc-center">
          <dv-decoration-10 :color="['#568aea', 'rgba(86, 138, 234, .3)']" style="width:33.3%;height:.0625rem;transform: rotateY(180deg);" />
          <div class="d-flex jc-center ai-center">
           
            <div class="title">
             <div style="height:1rem;overflow: hidden;">
              <img style="height:1rem;margin-right:0.3rem;" :src="require('../assets/fhj.png')" />
            </div>
              <div class="title-text">{{title}}</div>
            </div>
          </div>
          <dv-decoration-10 :color="['#568aea', 'rgba(86, 138, 234, .3)']" style="width:33.3%;height:.0625rem;" />
        </div>
        <div class="d-flex jc-center px-2">
          <dv-decoration-8 :color="['#568aea', '#568aea']" style="width:3rem;height:.625rem;" />
          <span style="font-size: 0.3rem;line-height:.625rem;color:#01a2e8;margin:0 2rem;">{{dateYear}} {{dateWeek}} {{dateDay}}</span>
          <dv-decoration-8 :reverse="true" :color="['#568aea', '#568aea']" style="width:3rem;height:.625rem;"/>
        </div>
        <div class="body-box flex-1">
          <div class="left-box2 h-100">
            <div class="h-100">
              <dv-border-box-12 >
                <abnormalTrend :data="data.lastWeekAbnormalDevList" />
              </dv-border-box-12>
            </div>
            <div class="h-100">
              <dv-border-box-12>
                <alarmRankList :list="data.lastWeekTowerEventRankList" :rowNum="7" />
              </dv-border-box-12>
            </div>
          </div>
          <div class="center-box2 h-100">
            <div class="h-100">
              <buildingCount :list="data" />
            </div>
            <div class="h-100 center-bottom" >
              <div class="w-100 h-100" >
                <timetotalLine :list="data.monthlyEventList" />
              </div>
              <div class="w-100 h-100" >
                <totalRing :data="data.deviceMonthlyStatis" />
              </div>
            </div>
          </div>
          <div class="right-box">
            <div >
              <dv-border-box-12>
                <monitor :list="monitorList" />
              </dv-border-box-12>
            </div>
            <div class="h-100">
              <dv-border-box-12>
                <todayNotice :list="todayEvent" @playMonitor="playMonitor" />
              </dv-border-box-12>
            </div>
          </div>
        </div>
      </div>
    </dv-full-screen-container>
  </div>
</template>

<script>
  import bus from '@/utils/bus';
  import { formatTime } from '../utils/index.js'
  import monitor from "./monitor";
  import timetotalLine from '@/components/echart/comm/timetotal-line'
  import totalRing from '@/components/echart/comm/total-ring'
  import buildingCount from '@/views/building/building-count'
  import todayNotice from '@/views/building/today-notice'
  import alarmRankList from "./alarmRankList";
  import abnormalTrend from "./abnormalTrend";
  export default {
    data() {
      return {
        title: '昆明天汉科技有限公司消防监测平台',
        dateTimer: null,
        loading: true,
        dateDay: null,
        dateYear: null,
        dateWeek: null,
        weekday: ["周日", "周一", "周二", "周三", "周四", "周五", "周六"],
        timer: null,
        data: null,
        monitorList: [],
        todayEvent: []
      };
    },
    components: {
      monitor,
      timetotalLine,
      totalRing,
      buildingCount,
      todayNotice,
      alarmRankList,
      abnormalTrend
    },
    mounted() {
      this.timeFn();
    },
    created () {
      if (this.$route.query.token) {
        localStorage.setItem('baseUrl', 'https://service.fhj.thesetech.com');
        localStorage.setItem('token', this.$route.query.token);
      }
      this.getData();
      this.getMonitorList();
      this.getTodayEvent();
      this.timer && clearInterval(this.timer);
      this.timer = setInterval(() => {
        this.getData();
        this.getMonitorList();
        this.getTodayEvent();
      }, 120000);
    },
    destroyed () {
      this.dateTimer && clearInterval(this.dateTimer);
      this.timer && clearInterval(this.timer);
    },
    methods: {
      playMonitor(e) {
        bus.$emit('monitorSetUrl', e);
      },
      timeFn() {
        this.dateTimer && clearInterval(this.dateTimer);
        this.dateTimer = setInterval(() => {
          this.dateDay = formatTime(new Date(), 'HH: mm: ss');
          this.dateYear = formatTime(new Date(), 'yyyy-MM-dd');
          this.dateWeek = this.weekday[new Date().getDay()];
        }, 1000)
      },
      getData () {
        this.$get(this.$apis.wechatUserIndex).then(res => {
          if (res&&res.code === 0) {
            this.data = res.data;
            if (res.data.areaOperational.area) {
              this.title = `${res.data.areaOperational.area}昆明天汉科技有限公司消防监测平台`;
            }
            this.loading = false;
          }
        });
      },
      getMonitorList () {
        this.$get(this.$apis.monitorList, {
          type: 0,
          keyword: ''
        }).then(res => {
          if (res&&res.code === 0) {
            this.monitorList = res.data;
          }
        });
      },
      getTodayEvent() {
        var obj = {
          keyword: '',
          pageNum: 1,
          pageSize: 1000,
          status: 0
        }
        this.$get(this.$apis.todayEvent, obj).then(res => {
          if (res&&res.code === 0) {
            this.todayEvent = res.data.list;
          }
        });
      }
    }
  };
</script>

<style lang="scss" scoped>
@import '../assets/scss/index.scss';
</style>