<template>
  <div id="todayNotice">
    <div class="bg-color-black d-flex flex-column">
      <div class="d-flex ai-center py-2 pl-2">
        <span style="color:#5cd9e8">
          <icon name="calendar" scale="0.8"></icon>
        </span>
        <div class="d-flex ai-center flex-1">
          <span class="text mx-2">今日事件</span>
        </div>
      </div>
      <div class="d-flex jc-center flex-1 body-box">
        <dv-scroll-board ref="todayNoticeRef" :config="config" style="width:100%;height:100%;cursor:pointer;" @click="click" />
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        config: {
          header: ["数据"],
          data: [],
          headerHeight: 0,
          headerBGC: "#0f1325", //表头
          oddRowBGC: "#0f1325", //奇数行
          evenRowBGC: "#171c33", //偶数行
          rowNum: 4,
          index: false,
          columnWidth: [1000],
          align: ["left"],
          hoverPause: true,
          waitTime: 1000
        }
      }
    },
    props: {
      list: {
        type: Array,
        default () {
          return []
        }
      }
    },
    watch: {
      list: {
        handler(newData) {
          var arr = [];
          newData.forEach(element => {
            arr.push([
              `<div class='d-flex jc-center ai-start h-100 flex-column'>
                <div class='notice' style='color:#11d0c9;'>时间&nbsp;&nbsp;[${element.alarmTime}]</div>
                <div class='notice' style='color:#11d0c9;'>类型&nbsp;&nbsp;[${element.deviceStatusName}]</div>
                <div class='notice' style='color:#11d0c9;'>设备&nbsp;&nbsp;[${element.productName}]</div>
                <div class='notice' style='color:#11d0c9;'>楼宇&nbsp;&nbsp;[${element.floor}]</div>
                <div class='notice' style='color:#11d0c9;'>方位&nbsp;&nbsp;[${element.installPositionName}]</div>
                <div class="notice" style='color:#11d0c9;'>监控&nbsp;&nbsp;<span style="color:#11d0c9">▶</span></div>
              </div>`
            ]);
          });
         this.$nextTick(() => {
            setTimeout(() => {
              this.$refs.todayNoticeRef.updateRows(arr);
            }, 0);
          });
        },
        immediate: true,
        deep: true
      }
    },
    methods: {
      click (e) {
        this.$emit('playMonitor', this.list[e.rowIndex].monitorDeviceBaseInfoVO?.previewUrlHd);
      },
    }
  }
</script>

<style lang="scss">
.notice {
  font-size: 0.15rem;
  line-height: 1.5 !important;
}
</style>
<style lang="scss" scoped>
  #todayNotice {
    padding: 0.2rem;
    width: 100%;
    height: 100%;
    border-radius: 0.0625rem;
    box-sizing: border-box;
    .bg-color-black {
      width: 100%;
      height: 100%;
    }
    .text {
      font-size: 0.15rem;
      color: #c3cbde;
    }
    .body-box {
      height: 100%;
      overflow: hidden;
    }
  }
</style>